import React from 'react'
import Layout from '../../components/layout';
import Helmet from "react-helmet"
import SearchComponent from "../../components/search/searchpulse"

const Home = () => {
    return (
        <Layout includeSearchBar={false}>
            <Helmet>
                <title>Vantage Pulse </title>

                <meta name="description" content="Join our team as we redefine the rewards and recognition infrastructure for thousands of businesses globally. Check out available openings." />
                <meta property="twitter:title" content="Vantage Circle" />
                        
                <meta property="twitter:description" content="Join our team as we redefine the rewards and recognition infrastructure for thousands of businesses globally. Check out available openings." />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://docs.vantagecircle.com/vantage-pulse/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <meta property="og:title" content="Vantage Circle" />

                <meta property="og:description" content="Join our team as we redefine the rewards and recognition infrastructure for thousands of businesses globally. Check out available openings." />
                <link rel="canonical" href="https://docs.vantagecircle.com/vantage-pulse/"></link>
            </Helmet>
            <SearchComponent />
            <section className="container max-w-6xl mx-auto py-10 md:py-10">
                <div className="mx-auto px-6 mb-10">
                    <div className="text-center">
                        <h2 className="section-title"><span className="text-orange">Browse</span> Resources by Role</h2>
                    </div>
                </div>
                <div className="max-w-3xl mx-auto grid grid-cols-1 gap-5 md:gap-6 sm:grid-cols-1 md:grid-cols-2 items-center px-6">
                    <div className="h-full card-1 w-full rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out">
                        <div className="bg-white p-8">
                            <div className="relative mb-10">
                                <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/v1641463704/gatsbycms/uploads/2022/01/Hradmin.png" alt="HR Administrators" />
                            </div>
                            <div className="mt-5 sm:mt-8 md:mt-10 flex justify-center">
                                <div className="">
                                    <a href="/vantage-pulse/hr-administrators/" className="vc-colored-btn">HR Administrators</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-full card-1 w-full rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out">
                        <div className="bg-white p-8">
                            <div className="relative mb-10">
                                <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/v1641463704/gatsbycms/uploads/2022/01/Employees.png" alt="Employees" />
                            </div>
                            <div className="mt-5 sm:mt-8 md:mt-10 flex justify-center">
                                <div className="">
                                    <a href="/vantage-pulse/employees/" className="vc-colored-btn">Employees</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section class="container max-w-4xl mb-10 px-6">
                <div class="bg-indigo-100 rounded-lg shadow-xl relative z-9 overflow-hidden sm:flex">
                    <div class="p-10 md:pl-20 md:pr-0 bottom-cta-content text-white sm:w-2/3 w-full">
                        <div class="section-title-white text-center sm:text-left">Concepts on<br/> Employee Engagement & Survey</div>
                        <div class="mt-5 sm:mt-8 md:mt-8 md:flex">
                            <div class="">
                                <a href="/vantage-rewards/employeeengagement-concepts/" class="vc-ghost-btn-cta-small-banner">
                                    Know more
                                </a>
                            </div>
                        </div> 
                    </div>
                    <div class="sm:w-1/3 relative mob hidden sm:block">
                        <img loading="lazy" class="absolute bottom-0" src="https://res.cloudinary.com/vantagecircle/image/upload/v1645771873/gatsbycms/uploads/2022/02/doc-survey.png" alt="Concepts on Employee Engagement & Survey"/>
                    </div>
                </div>
                <img loading="lazy" class="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png" alt="VC white dots" width="90" height="90"/>
                <img loading="lazy" class="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg" alt="VC flower" width="150" height="150"/>
            </section> */}
            <section className="container max-w-4xl vc-doc-content px-12 pb-20">
                <h2>Overview</h2>
                <p>The following documentation is aimed to understand the key features of the product and it’s also a step by step guide on how to use the platform. This particular manual will be updated continuously with the sole purpose to help our users with new features and product enhancements.</p>
                <h2>What is Vantage Pulse?</h2>
                <p>Vantage Pulse is a powerful online employee survey tool that lets you collect, measure, and act on real-time employee feedback.</p>
                <h2>What makes it different from a traditional survey tool?</h2>
                <p>Unlike traditional surveys, Vantage Pulse keeps track of engagement levels through the use of eNPS which stands for Employee Net Promoter Score. It makes it easier for organizations to measure employee loyalty in a quick and effective way.</p>
                <p>With Vantage Pulse, managers get data-driven results to analyze trends, uncover factors of employee turnover, predict behaviors, and ultimately increase employee retention.</p>
                <p>It helps you overcome the gap between feedback and action with real-time insights. You get the right data from your workforce exactly when you need it. In short, it is the best way to future-proof your strategies to manage your people.</p>
                <h2>How does Vantage Pulse work?</h2>
                <p>Vantage Pulse gives you the ability to create surveys and decide on the frequency of the surveys. On the user’s end, the Pulse survey can be accessed through their email addresses via both, web and mobile devices.</p>
                <p>Pulse gives organizations the additional benefit of analyzing the gathered real-time data-backed feedback through segmentation, participation rate, and predictions.</p>
            </section>
        </Layout>
    )
}

export default Home